.contact {
  width: 100%;
  position: relative;
  display: grid;
  grid: auto / auto;
}

.contact img {
  width: 100%;
  filter: brightness(60%);
  min-height: 200px;
  object-fit: cover;
  grid-area: 0 / 1 / 0 / 1;
}

.contact .cover {
  position: absolute;
  top: 0;

  grid-area: 0 / 1 / 0 / 1;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.contact .content {
  position: absolute;
  grid-area: 0 / 1 / 0 / 1;
  z-index: 2;
  align-self: center;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.contact .text {
  padding-left: 5rem;
}

.contact h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contact p {
  color: white;
  margin-bottom: 0.3rem;
}

.contact button {
  font-size: x-large;
  height: 3rem;
  width: 13rem;
  background-color: white;
  color: #373737;
  border: none;
  margin-bottom: 1.5rem;
}

.contact button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.contact button:active {
  background-color: rgba(255, 255, 255, 0.8);
}

.contact a {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  cursor: pointer;
}

@media screen and (min-width: 480px) {
  .contact a {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 480px) {
  .contact .text {
    padding-left: 1rem;
  }
}

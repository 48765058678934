@import "reset.css";
@import "navbar.css";
@import "contact.css";
@import "despre.css";
@import "servicii.css";

.app {
  width: "100%";
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
button,
a,
p {
  font-family: "Inter", sans-serif;
}

h1 {
  font-weight: 800;
  /* font-size: 2.5rem; */
  color: white;
}

a {
  text-decoration: none;
  color: white;
}

button {
  background-color: transparent;
  border: solid;
  color: white;
  border-width: 0.1rem;
  border-radius: 6px;

  height: 3rem;
  width: 10rem;

  padding-left: 2rem;
  padding-right: 2rem;

  font-size: 1.3rem;
  font-weight: 700;

  transition: 0.3s;
  cursor: pointer;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

button:active {
  color: black;
  background-color: rgba(255, 255, 255);
  border-color: white;
  transition: none;
}

.container {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.hero {
  width: 100%;
  display: grid;
  grid: auto / auto;
}

.hero picture {
  width: 100%;
  grid-area: 1 / 1 /1 /1;
  display: flex;
  height: 700px;
}
.hero img {
  width: 100%;
  filter: brightness(60%);
  object-fit: cover;
  object-position: left;
  height: 100%;
}

.hero .textContainer {
  z-index: 1;
  align-self: center;
  justify-self: end;
  grid-area: 1 /1 /1 /1;

  width: 70%;

  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 480px) {
  .hero .textContainer h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .hero .textContainer {
    margin-top: 2rem;
    width: 90%;
    justify-self: center;
    gap: 1.2rem;
  }
}

section {
  /* height: 400px; */
  width: 100%;
}

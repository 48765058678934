.servicii {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.servicii .container {
  display: grid;
  width: 100%;
  justify-items: center;

  margin: 2rem 5rem;
}

/* For big screens */
@media (min-width: 768px) {
  .servicii .container {
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
  }
}

/* For small screens */
@media (max-width: 767px) {
  .servicii .container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */

    margin: 2rem 1rem 0 1rem;
  }
}

.servicii picture {
  width: 120px;
  height: 60px;

  margin: 20px;
  /*  */
  display: flex;
  align-items: center;
}

.servicii img {
  width: 100%;
}

.servicii .text {
  margin: 0 4rem 2rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  .servicii .text {
    margin: 0 2rem 1rem;
  }
}
